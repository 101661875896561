import React, {Component} from 'react';
import axios from "axios";
import {ThreeDots} from "react-loader-spinner";
import "./DateTimePicker.css";
import {connect} from "react-redux";
import {MsalContext} from '@azure/msal-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormGroup,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {AutoDelete, History, Schedule} from "@mui/icons-material";
import {
    addScheduleSlackThunk,
    deleteScheduleSlackThunk,
    getAllSurveysWithScheduleSlackThunk
} from "../../../../redux/slack/survey/survey.thunk";
import {getUserSlackThunk} from "../../../../redux/slack/users/users.thunk";


class SlackDateTimePicker extends Component {

    static contextType = MsalContext;

    state = {
        isOpen: false,
        openMenu: null,
        type: "SCHEDULE",
        //0 - 6, Sunday - Saturday
        days: [false, false, false, false, false, false, false],
        myDays: [],
        time: "09:00",
        timezone: momentTz.tz.guess(),
        dateTime: new Date().setHours(9, 0, 0),
        sending: false,
        success: false,
        userId: '',
        message: ''
    }

    //let teamId = this.context.accounts[0].idTokenClaims.extension_teamId;  TODO change to this

    componentDidMount() {
        if (this.props.scheduleId) {
            this.setState({type: "EDIT"});
        }

    }

    handleClickOpen = () => {
        // console.log('hola!');
        this.setState({isOpen: true});
        if (this.props.days) {
            const newDays = [];
            this.props.days.map((item, index) => {
                if (item) {
                    newDays.push(index.toString())
                }
            });
            this.setState({days: this.props.days, myDays: newDays});

        }

        if (this.props.hours) {

            let myMinutes = this.props.minutes;
            let myHours = this.props.hours;
            if (this.props.minutes < 10) {
                myMinutes = "0" + this.props.minutes;
            }

            if (this.props.hours < 10) {
                myHours = "0" + this.props.hours;
            }

            let myTime = myHours + ":" + myMinutes;


            this.setState({time: myTime, dateTime: new Date().setHours(myHours, myMinutes, 0)});
        }
    };

    handleClose = () => {
        this.setState({isOpen: false});
        this.setState({success: false});
    };

    handleDayChange = (e) => {
        let newDays = [...this.state.days];

        newDays[e.target.name] = !this.state.days[e.target.name];
        this.setState({days: newDays});


    }

    handleDays = (e, days) => {
        if (days.length) {
            this.setState({myDays: days});
        }
        let newDays = [false, false, false, false, false, false, false];
        days.map(item => {
            newDays[item] = true;
        });
        this.setState({days: newDays});
    }

    handleTimeChange = (e) => {
        const timeLocal = moment(e).format('HH:mm');
        const zone = momentTz.tz.guess();
        console.log('TIME LOCALL!', e, timeLocal, zone);
        this.setState({dateTime: new Date(e), time: timeLocal, timezone: zone});
    }

    atLeastOneDay = () => {
        let res = false;
        this.state.days.forEach((day) => {
            if (day) {
                res = true;
            }
        })
        return res;
    }

    handleSubmit = async () => {
        console.log("SUBMIT");

        if (this.atLeastOneDay()) {
            //no way to edit a durable function running instance so we have to delete it and create a new one
            if (this.state.type === "EDIT") {


                //need to delete an instance from function app
                await this.handleDelete(this.props.scheduleId, "edits");


                //need to delete from db
            }

            let myTime = this.state.time;
            console.log('TIME', myTime);
            let str = myTime.split(':');

            let teamId = this.props.selectedTeam;

            let obj = {
                days: this.state.days,
                hours: str[0],
                minutes: str[1],
                timeZone: this.state.timezone,
                surveyId: this.props.surveyId,
                state: true,
                teamId: teamId
            };
            console.log(obj);
            this.setState({sending: true});

            await this.props.getUserSlack(this.context.accounts[0].username);

            let userId;

            for (let us of this.props.user) {
                if (us.teamId === this.props.selectedTeam) {
                    userId = us.id;
                }
            }

            let res = await axios.post("https://slack-survey-schedule.azurewebsites.net/api/orchestrators/NewSchedule", obj);
            console.log(res);
            if (res.status === 202) {
                obj.id = res.data.id;
                obj.slackSurveyId = obj.surveyId;
                obj.slackUserId = userId;


                await this.props.addScheduleSlack(obj);


                this.setState({message: "Successfully scheduled"});
                this.setState({success: true});
            }

// //--------//
//               obj.id="1234567891012";
//            await this.props.addScheduleSlack(obj);
//            this.setState({message: "Successfully scheduled"});
//             this.setState({ success: true });
//             //-------//


            this.setState({sending: false});
            this.props.getAllSurveysWithScheduleSlack(teamId);
        }
    }

    handleDelete = async (scheduleId, reason) => {
        this.setState({sending: true});
        let res = await axios.post(`https://slack-survey-schedule.azurewebsites.net/runtime/webhooks/durabletask/instances/${scheduleId}/terminate?reason=${reason}&taskHub=slacksurveyschedule&connection=Storage&code=h6HQN7J805y4e80yV2m3svyP8/BqDukuuK4NBozo3ewLDh9Q4VCGeQ==`);
        console.log(res);
        if (res.status === 202) {
            console.log("Deleted an instance from azure");

            await this.props.deleteScheduleSlack(scheduleId);
            this.setState({sending: false});
            this.setState({message: "Successfully deleted"})
            this.setState({success: true});
        }


        if (reason !== "edits") {

            this.props.getAllSurveysWithScheduleSlack(this.props.selectedTeam);
        }


    }

    handleOpenMenu = (event) => {
        this.setState({openMenu: event.currentTarget})
    }

    handleCloseMenu = () => {
        this.setState({openMenu: null})
    }

    render() {

        return (
            <div className={this.props.children ? 'w-100' : ''}>
                <Tooltip title="Schedule">
                    {this.state.type === 'SCHEDULE' ?
                        this.props.children ?
                            <Button onClick={this.handleClickOpen}
                                    className={this.props.className}>{this.props.children}</Button>
                            :
                            <IconButton onClick={this.handleClickOpen}>
                                <Schedule/>
                            </IconButton>
                        :
                        this.props.children ?
                            <Button onClick={this.handleOpenMenu}
                                    className={this.props.className}>{this.props.children}</Button>
                            : <IconButton onClick={this.handleOpenMenu}><Schedule/></IconButton>
                    }
                </Tooltip>
                {this.state.type === 'EDIT' ?
                    <Menu anchorEl={this.state.openMenu} open={Boolean(this.state.openMenu)}
                          keepMounted
                          onClose={this.handleCloseMenu}
                          className="h-menu-share">
                        <MenuItem onClick={this.handleClickOpen}>
                            <Typography textAlign="center" className="text-black-50">
                                <History/> Edit
                            </Typography>
                        </MenuItem>
                        <MenuItem color="danger"
                                  onClick={() => this.handleDelete(this.props.scheduleId, "user canceled by clicking a button")}>
                            <Typography textAlign="center" className="text-danger">
                                <AutoDelete/> Delete
                            </Typography>
                        </MenuItem>
                    </Menu> : ''}
                <Dialog open={this.state.isOpen} onClose={this.handleClose}>
                    {this.state.success ?
                        <div className="successBlock">
                            <Typography>{this.state.message}</Typography>
                            <Button onClick={this.handleClose} color="primary">
                                Close
                            </Button>
                        </div> :
                        <div>
                            <DialogContent>
                                <p className="text-black-50">SELECT DAYS</p>

                                <FormGroup>
                                    {/*<FormGroup>
                                        <FormControlLabel control={<Switch checked={this.state.days[1]} name={'1'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Monday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[2]} name={'2'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Tuesday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[3]} name={'3'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Wednesday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[4]} name={'4'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Thursday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[5]} name={'5'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Friday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[6]} name={'6'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Saturday"/>
                                        <FormControlLabel control={<Switch checked={this.state.days[0]} name={'0'}
                                                                           onChange={this.handleDayChange}/>}
                                                          label="Sunday"/>
                                    </FormGroup>*/}

                                    <ToggleButtonGroup value={this.state.myDays}
                                                       onChange={this.handleDays}
                                                       color="primary"
                                                       className="justify-content-center"
                                                       aria-label="myDays">
                                        <ToggleButton value="1" aria-label="monday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Mon
                                        </ToggleButton>
                                        <ToggleButton value="2" aria-label="tuesday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Tue
                                        </ToggleButton>
                                        <ToggleButton value="3" aria-label="wednesday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Wed
                                        </ToggleButton>
                                        <ToggleButton value="4" aria-label="thursday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Thur
                                        </ToggleButton>
                                        <ToggleButton value="5" aria-label="friday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Fri
                                        </ToggleButton>
                                        <ToggleButton value="6" aria-label="saturday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Sat
                                        </ToggleButton>
                                        <ToggleButton value="0" aria-label="sunday"
                                                      sx={{fontSize: {xs: '0.6rem', md: '1rem'}}}>
                                            Sun
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker displayStaticWrapperAs="mobile"
                                                          value={this.state.dateTime}
                                                          onChange={this.handleTimeChange}
                                                          renderInput={(params) => <TextField {...params} />}/>
                                    </LocalizationProvider>
                                </FormGroup>

                            </DialogContent>

                            <DialogActions>
                                <ThreeDots color="#EC2C93"
                                           height="100"
                                           width="100"
                                           visible={this.state.sending}/>
                                <div className="text-end w-100">
                                    <Button onClick={this.handleClose} color="primary">
                                        Cancel
                                    </Button>

                                    <Button variant="contained" onClick={this.handleSubmit} color="primary" disabled={!this.atLeastOneDay()}>
                                        Ok
                                    </Button>
                                </div>

                            </DialogActions>
                        </div>
                    }

                </Dialog>
            </div>
        );
    }
}

// Map state to props;
const mapStateToProps = (state) => {
    return {
        user: state.users.user,
        selectedTeam: state.users.selectedTeam
    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {

        addScheduleSlack: (obj) => dispatch(addScheduleSlackThunk(obj)),
        deleteScheduleSlack: (id) => dispatch(deleteScheduleSlackThunk(id)),
        getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        getUserSlack: (email) => dispatch(getUserSlackThunk(email))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SlackDateTimePicker);
